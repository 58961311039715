/* --------------------------------------------------------------------------
| FUNCTIONS DROPDOWN
|-------------------------------------------------------------------------- */

import { handleAddClass, handleRemoveClass, handleVerifyElementExists } from './functions-helpers';

/* --------------------------------------------------------------------------
| FUNCTION -> TOGGLE, ALTERNA ENTRE ABRIR E FECHAR O DROPDOWN...
|-------------------------------------------------------------------------- */
export const handleToggleDropdown = (event) => {
    let triggerId = event.currentTarget.id;
    let triggerCorrespondingMenu = event.currentTarget.querySelector('.dropdown-menu');
    let triggerCorrespondingMenuData = triggerCorrespondingMenu.dataset.dropdown;
    let triggerCorrespondingMenuIcon = triggerCorrespondingMenu.querySelector('.iconToggleDropdownTrigger')
        ? triggerCorrespondingMenu.querySelector('.iconToggleDropdownTrigger')
        : null;

    if (triggerId === triggerCorrespondingMenuData) {
        if (triggerCorrespondingMenu.classList.contains('dropdown-menu--show')) {
            handleRemoveClass(triggerCorrespondingMenu, 'dropdown-menu--show');

            if (triggerCorrespondingMenuIcon !== null) {
                handleAddClass(triggerCorrespondingMenuIcon, 'navigation__arrowUp');
                handleRemoveClass(triggerCorrespondingMenuIcon, 'navigation__arrowDown');
            }

        } else {
            handleAddClass(triggerCorrespondingMenu, 'dropdown-menu--show');

            if (triggerCorrespondingMenuIcon !== null) {
                handleAddClass(triggerCorrespondingMenuIcon, 'navigation__arrowDown');
                handleRemoveClass(triggerCorrespondingMenuIcon, 'navigation__arrowUp');
            }
        }
    }
}

/* --------------------------------------------------------------------------
| FUNCTION OPEN/CLOSE BUTTON MENU DROPDOWN...
|-------------------------------------------------------------------------- */
export const handleSelectCorrespondingDropdown = (element) => {
	let button = element.currentTarget.id;
	let menus = document.querySelectorAll(".dropdown-button-menu");

	handleVerifyElementExists(menus, () => {
		menus.forEach((element) => {
			let data = element.getAttribute("data-id");

			if (data === button) {
				if (element.classList.contains("dropdown-button-menu--none")) {
					element.classList.remove("dropdown-button-menu--none");
				} else {
					element.classList.add("dropdown-button-menu--none");
				}
			} else {
				if (!element.classList.contains("dropdown-button-menu--none")) {
					element.classList.add("dropdown-button-menu--none");
				}
			}
		});
	});
};
