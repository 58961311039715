/*
|--------------------------------------------------------------------------
|-------------------------| FUNCTIONS ASIDE |------------------------------
|--------------------------------------------------------------------------
*/

/* --------------------------------------------------------------------------
| FUNCTION QUE SELECIONA O ASIDE...
|-------------------------------------------------------------------------- */
export const handleSelectedAside = () => {
    let asideElement = document.querySelector('.aside');
    return asideElement;
}

/* --------------------------------------------------------------------------
| FUNCTION QUE ASIDE TOGGLE, ALTERNA ENTRE ABRIR OU FECHAR...
|-------------------------------------------------------------------------- */
export const handleToggleAside = () => {
    let elem = handleSelectedAside();

    (elem.classList.contains('aside--show')) // Verifica se o display é igual a none...
        ? handleCloseAside(elem) // false - chama a função de close...
        : handleOpenAside(elem); // true - chama a função de open...
}

/* --------------------------------------------------------------------------
| FUNCTION QUE FECHA O ASIDE...
|-------------------------------------------------------------------------- */
export const handleCloseAside = (aside) => {
    // aside.style.setProperty('--aside', '0rem');
    aside.classList.remove('aside--show');
	aside.classList.add('aside--close');
}

/* --------------------------------------------------------------------------
| FUNCTION QUE ABRE O ASIDE...
|-------------------------------------------------------------------------- */
export const handleOpenAside = (aside) => {
    // aside.style.setProperty('--aside', '43.75rem');
    aside.classList.add('aside--show');
	aside.classList.remove('aside--close');
}

/* --------------------------------------------------------------------------
| FUNCTION QUE ABRE O ASIDE FULL DE LARGURA...
|-------------------------------------------------------------------------- */
// export const handleOpenAsideFull = () => {
//     let aside = handleSelectedAside();
//     aside.style.setProperty('--aside', '100%');
//     aside.classList.add('aside--show');
// }
