/*
|--------------------------------------------------------------------------
|-------------------------| FUNCTIONS MODAL |------------------------------
|--------------------------------------------------------------------------
*/

import { handleAlertOpenWithText } from "./functions-alert";

/* --------------------------------------------------------------------------
| FUNCTION -> SELECIONA O MODAL...
|-------------------------------------------------------------------------- */
export const handleSelectModal = (event) => {
    let modalAction = event.currentTarget.getAttribute('data-modal'); // Seleciona o id do elemento que aciona o modal...
    let modal = document.querySelector(`#${modalAction}`); // Seleciona o modal que tenha o mesmo id do elemento action...

    return modal;
}

/* --------------------------------------------------------------------------
| FUNCTION -> ABRE A MODAL...
|-------------------------------------------------------------------------- */
export const handleModalOpen = (event) => {
    let modalSelected = handleSelectModal(event); // Usa a função que seleciona o elemento...
    modalSelected.classList.add('modal--show');
}

/* --------------------------------------------------------------------------
| FUNCTION -> FECHA A MODAL...
|-------------------------------------------------------------------------- */
export const handleModalClose = (event) => {
    let elementAction = event.currentTarget; // Pega o elemento clicado...
    let parentElement = elementAction.closest('.modal'); // Seleciona o pai do elemento...

    parentElement.classList.remove('modal--show'); // Remove a class de show...
}

/* --------------------------------------------------------------------------
| FUNCTION -> PARA AÇÃO DE ABRIR O MODAL...
|-------------------------------------------------------------------------- */
export const handleShowModal = (modal, uri) => {
    handleModalOpen(modal);
    handleSetUri(uri);
}

/* --------------------------------------------------------------------------
| FUNCTION -> DELETAR ELEMENTO PELA MODAL...
|-------------------------------------------------------------------------- */
export const handleModalDeleteItem = (event) => {
    let element = event.currentTarget;
    let elementUri = element.dataset.uri;
    let elementRedirect = element.dataset.redirect;

    axios.delete(elementUri)
        .then(() => {
            (elementRedirect == 'reload')
                ? window.location.reload()
                : window.location.replace(elementRedirect);

        }).catch((response) => {
            let alertElement = document.querySelector('#alert_proposition_delete_fail');
            let modal = document.querySelector('#modal-delete-proposition');
            handleAlertOpenWithText(response.response.data.error, alertElement, modal);
        });
}

/* --------------------------------------------------------------------------
| FUNCTION -> PEGA A URI DO BOTÃO DE ABERTURA DA MODAL...
|-------------------------------------------------------------------------- */
export const handleSetUri = (uri) => {
    const buttonAction = document.querySelectorAll('.buttonActionModal');

    buttonAction.forEach((element)=>{
        element.dataset.uri = uri;
        element.addEventListener('click', (event) => {
            handleModalDeleteItem(event);
        });
    });
}
