/* --------------------------------------------------------------------------
| FUNCTIONS ALERT
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| FUNCTION -> SELECIONA O ALERTA...
|-------------------------------------------------------------------------- */

export const handleSelectedAlert = () => {
    let alert = document.querySelector('.alert');
    return alert;
}

/* --------------------------------------------------------------------------
| FUNCTION -> ABRE O ALERTA...
|-------------------------------------------------------------------------- */
export const handleAlertOpen = (alert) => {
    alert.classList.remove('alert--close');
    alert.classList.add('alert--show');
}

export const handleAlertOpenWithText = (message, alert, modal)=>{
    alert.querySelector('p').innerHTML = message;
    alert.classList.remove('alert--close');
    alert.classList.add('alert--show');
    modal.classList.remove('modal--show');
}

/* --------------------------------------------------------------------------
| FUNCTION -> FECHA O ALERTA...
|-------------------------------------------------------------------------- */
export const handleAlertClose = (alert) => {
    alert.classList.remove('alert--show');
    alert.classList.add('alert--close');
}

/* --------------------------------------------------------------------------
| FUNCTION -> FECHA O ALERTA POR TEMPO...
|-------------------------------------------------------------------------- */
export const handleAlertTimeOutClose = (alert, time = 3000) => {
    if (alert.classList.contains('alert--timeout')) {
        setTimeout(() => {
            handleAlertClose(alert);
        }, time);
    }
}

/* --------------------------------------------------------------------------
| FUNCTION -> ALTERNA O ALERTA ENTRE ABRETO E FECHADO...
|-------------------------------------------------------------------------- */
export const handleToggleAlert = () => {
    let alert = handleSelectedAlert();

    (alert.classList.contains('alert--show')) // Verifica se o display é igual a none...
        ? handleAlertClose(alert) // false - chama a função de close...
        : handleAlertOpen(alert); // true - chama a função de open...
}
