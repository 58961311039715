/* --------------------------------------------------------------------------
|--------------------------| FILE HEAD JAVASCRIPT |--------------------------
|-------------------------------------------------------------------------- */

require('./bootstrap');

import { handleVerifyElementExists } from './functions/functions-helpers';
import { handleCloseAside, handleSelectedAside } from './functions/functions-aside';
import { handleModalClose, handleShowModal } from './functions/functions-modal';
import { handleAlertTimeOutClose } from './functions/functions-alert';
import { handleImageLetterUser } from './functions/functions-users';
import { handleSelectCorrespondingDropdown } from './functions/functions-dropdown';

/* --------------------------------------------------------------------------
|--------------------------------| ASIDE |-----------------------------------
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| BUTTON - CLOSE ASIDE...
|-------------------------------------------------------------------------- */
const buttonAsideCloseOver = document.querySelector('.buttonAsideClose');
handleVerifyElementExists(buttonAsideCloseOver, () => {
    buttonAsideCloseOver.addEventListener('click', () => {
        handleCloseAside(handleSelectedAside());
    });
});

/* --------------------------------------------------------------------------
|--------------------------------| MODAL |---------------------------------
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| FECHAR MODAL...
|-------------------------------------------------------------------------- */
const modalClose = document.querySelectorAll('.handleModalClose');
handleVerifyElementExists(modalClose, () => {
    modalClose.forEach((element) => {
        element.addEventListener('click', (event) => {
            handleModalClose(event);
        });
    });
});

/* --------------------------------------------------------------------------
|---------------------------------| ALERTS |----------------------------------
|-------------------------------------------------------------------------- */

const closesAlerts = document.querySelectorAll('.alert__button-close');
handleVerifyElementExists(closesAlerts, () => {
    closesAlerts.forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();

            let button = event.currentTarget;
            let alert = button.closest('article.alert');

            alert.classList.remove('alert--show');
        });
    });
});

/* --------------------------------------------------------------------------
| CLOSE ALERTS POR TEMPO...
|-------------------------------------------------------------------------- */
window.addEventListener('load', () => {
    const closeAlertForTime = document.querySelectorAll('.alert.alert--timeout');
    handleVerifyElementExists(closeAlertForTime, () => {
        closeAlertForTime.forEach((element) => {
            handleAlertTimeOutClose(element, 5000);
        });
    });
});

/* --------------------------------------------------------------------------
|----------------------------------| FILES |--------------------------------
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| ABRE MODAL PARA DELETAR ARQUIVOS...
| ATA, PROPOSIÇÃO
|-------------------------------------------------------------------------- */
const showModalDeleteFiles = document.querySelectorAll('.button-modal-delete-files');
handleVerifyElementExists(showModalDeleteFiles, () => {
    showModalDeleteFiles.forEach(element => {
        element.addEventListener('click', (event) => {
            let uri = event.currentTarget.getAttribute('data-uri');
            handleShowModal(event, uri);
        });
    });
});

/* --------------------------------------------------------------------------
|--------------------------------| USERS |-----------------------------------
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| PEGAR PRIMEIRA E SEGUNDA LETRA DO NOME DO USUÁRIO...
|-------------------------------------------------------------------------- */
const imageLetter = document.querySelectorAll('.letterFlag');
handleVerifyElementExists(imageLetter, () => {
    imageLetter.forEach((element) => {
        handleImageLetterUser(element);
    });
});


/* --------------------------------------------------------------------------
|--------------------------------| PRINTS |---------------------------------
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| EVENTO DE IMPRESSÃO...
|-------------------------------------------------------------------------- */
let print = document.querySelector('.print_button');
handleVerifyElementExists(print, () => {
    print.addEventListener('click', () => {
        window.print();
    });
});

/* --------------------------------------------------------------------------
|--------------------------------| THEMES |---------------------------------
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| TOGGLE THEME COLOR DARK OR LIGHT...
|-------------------------------------------------------------------------- */
const toggleTheme = document.querySelector('#switchThemeTrigger');
handleVerifyElementExists(toggleTheme, () => {
    toggleTheme.addEventListener('click', (event) => {
        handleToggleTheme(event);
    });
});

/* --------------------------------------------------------------------------
|----------------------------| BUTTON DROPDOWN |-----------------------------
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| ABRE E FECHA O BUTTON MENU DROPDOWN ...
|-------------------------------------------------------------------------- */
const dropdownButtons = document.querySelectorAll(".dropdown-button");
handleVerifyElementExists(dropdownButtons, () => {
	dropdownButtons.forEach((element) => {
		element.addEventListener("click", (event) => {
			handleSelectCorrespondingDropdown(event);
		});
	});
});
