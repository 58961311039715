/*
|--------------------------------------------------------------------------
|-------------------------| FUNCTIONS HELPERS |----------------------------
|--------------------------------------------------------------------------
*/

/* --------------------------------------------------------------------------
| VERIFICA SE O ELEMENTO FOI SELECIONADO...
|-------------------------------------------------------------------------- */
export const handleVerifyElementExists = (element, callback) => {
    return (element) ? callback() : null;
}

/* --------------------------------------------------------------------------
| FUNÇÃO QUE FAZ REQUISIÇÃO...
|-------------------------------------------------------------------------- */
export const handleFetch = async (uri) => {
    let response = await fetch(uri);
    let data = await response.json();

    return data;
}

/* --------------------------------------------------------------------------
| FUNCTION QUE LIMPA O HTML...
|-------------------------------------------------------------------------- */
export const handleCleanRender = (element) => {
    element.innerHTML = '';
}


/* --------------------------------------------------------------------------
| FUNCTION QUE ADICIONA UMA CLASSE EM UM ELEMENTO...
|-------------------------------------------------------------------------- */
export const handleAddClass = (element, classe) => {
    element.classList.add(`${classe}`);
}

/* --------------------------------------------------------------------------
| FUNCTION QUE REMOVE UMA CLASSE EM UM ELEMENTO...
|-------------------------------------------------------------------------- */
export const handleRemoveClass = (element, classe) => {
    element.classList.remove(`${classe}`);
}

/* --------------------------------------------------------------------------
| FUNCTION QUE RECEBE UM ARRAY E ADICIONA OU REMOVE A CLASS DO ELEMENTO...
|-------------------------------------------------------------------------- */
export const handleLoopArrayAddOrRemoveClass = (elements, type, classe) => {
    elements.forEach(element => {
        if (type === 'add') {
            element.classList.add(classe);
        } else if (type === 'remove') {
            element.classList.remove(classe);
        } else {
            return false;
        }
    });
}
